export const TOKEN_DECIMALS = 18;

export enum Networks {
  AVAX = 43114,
  HARDHAT = 31337,
  TESTNET = 5,
  MAINNET = 1,
  // MAINNET = 31337,
}

export const DEFAULT_NETWORK = Networks.TESTNET;
// export const DEFAULT_NETWORK = Networks.HARDHAT;
