import { Networks } from "../../constants/blockchain";
import { LPBond, CustomLPBond } from "./lp-bond";
import { StableBond, CustomBond } from "./stable-bond";

import MimIcon from "../../assets/tokens/USDC.png";
import MimSpaceIcon from "../../assets/tokens/USDC.png";
import AvaiIcon from "../../assets/tokens/AVAI.svg";

import {
  StableBondContract,
  LpBondContract,
  //WavaxBondContract,
  StableReserveContract,
  LpReserveContract,
} from "../../abi";

export const weth = new StableBond({
  name: "weth",
  displayName: "weth",
  bondToken: "weth",
  decimals: 6,
  bondIconSvg: MimIcon,
  bondContractABI: StableBondContract,
  reserveContractAbi: StableReserveContract,
  networkAddrs: {
    [Networks.MAINNET]: {
      bondAddress: "0xb7678D99d0d22023cC3E7524A7c87817d43bfC14",
      reserveAddress: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
    },

    [Networks.TESTNET]: {
      bondAddress: "0xb7678D99d0d22023cC3E7524A7c87817d43bfC14",
      reserveAddress: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
    },

    [Networks.HARDHAT]: {
      bondAddress: "0xb7678D99d0d22023cC3E7524A7c87817d43bfC14",
      reserveAddress: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
    },
    [Networks.AVAX]: {
      bondAddress: "0xb7678D99d0d22023cC3E7524A7c87817d43bfC14",
      reserveAddress: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
    },
  },
});

// export const avai = new StableBond({
//   name: "avai",
//   displayName: "AVAI",
//   bondToken: "AVAI",
//   bondIconSvg: AvaiIcon,
//   bondContractABI: StableBondContract,
//   reserveContractAbi: StableReserveContract,
//   networkAddrs: {
//     [Networks.TESTNET]: {
//       bondAddress: "0x71594dF529db52875a03E500131c6Bf06bf2Fd60",
//       reserveAddress: "0x346A59146b9b4a77100D369a3d18E8007A9F46a6",
//     },
//     [Networks.HARDHAT]: {
//       bondAddress: "0x71594dF529db52875a03E500131c6Bf06bf2Fd60",
//       reserveAddress: "0x346A59146b9b4a77100D369a3d18E8007A9F46a6",
//     },
//     [Networks.AVAX]: {
//       bondAddress: "0x71594dF529db52875a03E500131c6Bf06bf2Fd60",
//       reserveAddress: "0x346A59146b9b4a77100D369a3d18E8007A9F46a6",
//     },
//   },
// });

/*
export const wavax = new CustomBond({
  name: "wavax",
  displayName: "wAVAX",
  bondToken: "AVAX",
  bondIconSvg: AvaxIcon,
  bondContractABI: WavaxBondContract,
  reserveContractAbi: StableReserveContract,
  networkAddrs: {
    [Networks.AVAX]: {
      bondAddress: "",
      reserveAddress: "",
    },
    [Networks.HARDHAT]: {
      bondAddress: "",
      reserveAddress: "",
    },
  },
});
*/
export const wethOHMSTRONG = new LPBond({
  name: "OHMSTRONG_weth_lp",
  displayName: "OHMSTRONG-weth LP",
  bondToken: "weth",
  decimals: 18,
  bondIconSvg: MimSpaceIcon,
  bondContractABI: LpBondContract,
  reserveContractAbi: LpReserveContract,
  networkAddrs: {
    [Networks.MAINNET]: {
      bondAddress: "0x53D501304620D5ab73201a479ea5aD9BDcF2c6fe",
      reserveAddress: "0x721681a68c9508555528eE56aA62AFf126f07745",
    },
    [Networks.TESTNET]: {
      bondAddress: "0x53D501304620D5ab73201a479ea5aD9BDcF2c6fe",
      reserveAddress: "0x721681a68c9508555528eE56aA62AFf126f07745",
    },
    [Networks.HARDHAT]: {
      bondAddress: "0x53D501304620D5ab73201a479ea5aD9BDcF2c6fe",
      reserveAddress: "0x721681a68c9508555528eE56aA62AFf126f07745",
    },
    [Networks.AVAX]: {
      bondAddress: "0x53D501304620D5ab73201a479ea5aD9BDcF2c6fe",
      reserveAddress: "0x721681a68c9508555528eE56aA62AFf126f07745",
    },
  },
  lpUrl:
    "https://app.BASEx.com/add/0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07/0xfe33888b9Fe8d193eA423C87DFb7aaBB789C688B",
});

export default [wethOHMSTRONG, weth];
