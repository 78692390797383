import { useState } from "react";
import { getAddresses, TOKEN_DECIMALS, DEFAULT_NETWORK } from "../../../constants";
import { useSelector } from "react-redux";
import { Link, Fade, Popper } from "@material-ui/core";
import "./space-menu.scss";
import { IReduxState } from "../../../store/slices/state.interface";
import { getTokenUrl } from "../../../helpers";

const addTokenToWallet = (tokenSymbol: string, tokenAddress: string) => async () => {
  const tokenImage = getTokenUrl(tokenSymbol.toLowerCase());

  if (window.ethereum) {
    try {
      await window.ethereum.request({
        method: "wallet_watchAsset",
        params: {
          type: "ERC20",
          options: {
            address: tokenAddress,
            symbol: tokenSymbol,
            decimals: TOKEN_DECIMALS,
            image: tokenImage,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  }
};

function TimeMenu() {
  const [anchorEl, setAnchorEl] = useState(null);
  const isEthereumAPIAvailable = window.ethereum;

  const networkID = useSelector<IReduxState, number>(state => {
    return (state.app && state.app.networkID) || DEFAULT_NETWORK;
  });

  const addresses = getAddresses(networkID);

  const SOHMSTRONG_ADDRESS = addresses.SOHMSTRONG_ADDRESS;
  const OHMSTRONG_ADDRESS = addresses.OHMSTRONG_ADDRESS;

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <div></div>
    // <div className="space-menu-root" onMouseEnter={e => handleClick(e)} onMouseLeave={e => handleClick(e)}>
    //   <div className="space-menu-btn">
    //     <p>OHMSTRONG</p>
    //   </div>

    //   <Popper className="space-menu-popper" open={open} anchorEl={anchorEl} transition>
    //     {({ TransitionProps }) => (
    //       <Fade {...TransitionProps} timeout={200}>
    //         <div className="tooltip">
    //           <Link
    //             className="tooltip-item"
    //             href={`https://app.mute.io/#/trade?inputCurrency=0x1&outputCurrency=0x2`}
    //             target="_blank"
    //           >
    //             <p>Buy on mute.io</p>
    //           </Link>

    //           {isEthereumAPIAvailable && (
    //             <div className="add-tokens">
    //               <div className="divider" />
    //               <p className="add-tokens-title">ADD TOKEN TO WALLET</p>
    //               <div className="divider" />
    //               <div className="tooltip-item" onClick={addTokenToWallet("OHMSTRONG", OHMSTRONG_ADDRESS)}>
    //                 <p>OHMSTRONG</p>
    //               </div>
    //               <div className="tooltip-item" onClick={addTokenToWallet("SOHMSTRONG", SOHMSTRONG_ADDRESS)}>
    //                 <p>sOHMSTRONG</p>
    //               </div>
    //             </div>
    //           )}
    //         </div>
    //       </Fade>
    //     )}
    //   </Popper>
    // </div>
  );
}

export default TimeMenu;
