import "./notfound.scss";

function NotFound() {
  return (
    <div className="not-found-page">
      <p>Page not found</p>
    </div>
  );
}

export default NotFound;
