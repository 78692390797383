import { Networks } from "./blockchain";

// LIVE
// OHMSTRONG    0x3CA9C7FA9f2354f6CF09128BA272E9E4A27f7fc3
// PRESALE 0x3CA9C7FA9f2354f6CF09128BA272E9E4A27f7fc3
const MAINNET = {
  DAO_ADDRESS: "0xE7b5e63BBC109E458dD95BB85671E548D0BEB3F2",
  OHMSTRONG_ADDRESS: "0x3CA9C7FA9f2354f6CF09128BA272E9E4A27f7fc3",
  USDC_ADDRESS: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
  OHMSTRONG_BONDING_CALC_ADDRESS: "0x74824772Fb09A2A8Af2242679FcF2574301993C9",
  TREASURY_ADDRESS: "0xE7160180AAa9298928fd6e489f656D0bd25903d0",
  SOHMSTRONG_ADDRESS: "0x01B32bB397a59aF636b05472c602D64E914C9865",
  STAKING_ADDRESS: "0x787651567A6cBfFaf51D71F0aC6f2F81C439890d",
  STAKING_HELPER_ADDRESS: "0x0000000000000000000000000000000000000000",
  DISTRIBUTOR_ADDRESS: "0xB575Fb8Cf2230eb735C6BED98958ec2533a0831C",
  ZAPIN_ADDRESS: "0x0000000000000000000000000000000000000000",
  BURN_ADDRESS: "0x000000000000000000000000000000000000dead",
  ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
  PRESALE_ADDRESS: "0x6f00c423Dcbcb4BCaB475A733E31B4f69F9dBfDB",
  WETH_ADDRESS: "0x4200000000000000000000000000000000000006",
};

const TESTNET = {
  DAO_ADDRESS: "0xE7b5e63BBC109E458dD95BB85671E548D0BEB3F2",
  OHMSTRONG_ADDRESS: "0x3CA9C7FA9f2354f6CF09128BA272E9E4A27f7fc3",
  USDC_ADDRESS: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
  OHMSTRONG_BONDING_CALC_ADDRESS: "0xE7160180AAa9298928fd6e489f656D0bd25903d0",
  TREASURY_ADDRESS: "0x9587195564f223337E98E4641c169c5C6EE92D58",
  SOHMSTRONG_ADDRESS: "0xa851d73f4E25f8c7077f208a5d1CEcBb71482De4",
  STAKING_ADDRESS: "0x3ACe9695eE2c18346259a3F2Ab15e68971D1A9eB",
  STAKING_HELPER_ADDRESS: "0x169A121908A099775f9Fb9B10BDad607F5b9206C",
  DISTRIBUTOR_ADDRESS: "0x0e2D367a17Ce4093AEcbb8935767D3F962e9C2ED",
  ZAPIN_ADDRESS: "0x0000000000000000000000000000000000000000",
  BURN_ADDRESS: "0x000000000000000000000000000000000000dead",
  ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
  PRESALE_ADDRESS: "0x6f00c423Dcbcb4BCaB475A733E31B4f69F9dBfDB",
  WETH_ADDRESS: "0x4200000000000000000000000000000000000006",
};

const HARDHAT = {
  DAO_ADDRESS: "0xE7b5e63BBC109E458dD95BB85671E548D0BEB3F2",
  OHMSTRONG_ADDRESS: "0x2Af51C874EFaa7b096005AA03D9a8A87afA7Dd56",
  USDC_ADDRESS: "0x15D38573d2feeb82e7ad5187aB8c1D52810B1f07",
  OHMSTRONG_BONDING_CALC_ADDRESS: "0xE7160180AAa9298928fd6e489f656D0bd25903d0",
  TREASURY_ADDRESS: "0x9587195564f223337E98E4641c169c5C6EE92D58",
  SOHMSTRONG_ADDRESS: "0xa851d73f4E25f8c7077f208a5d1CEcBb71482De4",
  STAKING_ADDRESS: "0x3ACe9695eE2c18346259a3F2Ab15e68971D1A9eB",
  STAKING_HELPER_ADDRESS: "0x169A121908A099775f9Fb9B10BDad607F5b9206C",
  DISTRIBUTOR_ADDRESS: "0x0e2D367a17Ce4093AEcbb8935767D3F962e9C2ED",
  ZAPIN_ADDRESS: "0x0000000000000000000000000000000000000000",
  BURN_ADDRESS: "0x000000000000000000000000000000000000dead",
  ZERO_ADDRESS: "0x0000000000000000000000000000000000000000",
  PRESALE_ADDRESS: "0xefE91D1ccF2fa31db2A5bCDB5F3D33A77db1a8AF",
  WETH_ADDRESS: "0x4200000000000000000000000000000000000006",
};

export const getAddresses = (networkID: number) => {
  if (networkID === Networks.TESTNET) return TESTNET;
  if (networkID === Networks.MAINNET) return MAINNET;
  if (networkID === Networks.HARDHAT) return HARDHAT;

  throw Error("Network not supported");
};

export const admin_wallets = ["0x9386DEd21e437BFf6d70C5C64dC11D80F5690d24"];

export const team_wallets = ["0x9386DEd21e437BFf6d70C5C64dC11D80F5690d24"];

export const isAdmin = (address: string) => {
  return admin_wallets.includes(address);
};

export const isTeam = (address: string) => {
  return team_wallets.includes(address) || isAdmin(address);
};
