import { ethers } from "ethers";
import { getAddresses } from "../../constants";
import {
  StakingHelperContract,
  SpaceTokenContract,
  SSpaceTokenContract,
  StakingContract,
  PresaleContract,
} from "../../abi";
import { clearPendingTxn, fetchPendingTxns, getStakingTypeText } from "./pending-txns-slice";
import { createAsyncThunk } from "@reduxjs/toolkit";
import { fetchAccountSuccess, getBalances, loadAccountDetails, loadWarmUpInfo } from "./account-slice";
import { loadAppDetails } from "./app-slice";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { Networks } from "../../constants/blockchain";
import { warning, success, info, error } from "../../store/slices/messages-slice";
import { messages } from "../../constants/messages";
import { getGasPrice } from "../../helpers/get-gas-price";
import { metamaskErrorWrap } from "../../helpers/metamask-error-wrap";
import { sleep } from "../../helpers";

interface IChangeApproval {
  token?: string;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
  address: string;
  networkID: Networks;
}

export const changeApproval = createAsyncThunk(
  "stake/changeApproval",
  async ({ token, provider, address, networkID }: IChangeApproval, { dispatch }) => {
    if (!provider) {
      dispatch(warning({ text: messages.please_connect_wallet }));
      return;
    }
    const addresses = getAddresses(networkID);

    const signer = provider.getSigner();
    const spaceContract = new ethers.Contract(addresses.OHMSTRONG_ADDRESS, SpaceTokenContract, signer);
    const sSpaceContract = new ethers.Contract(addresses.SOHMSTRONG_ADDRESS, SSpaceTokenContract, signer);

    let approveTx;
    try {
      const gasPrice = await getGasPrice(provider);

      if (token === "OHMSTRONG") {
        approveTx = await spaceContract.approve(addresses.STAKING_ADDRESS, ethers.constants.MaxUint256, {
          gasPrice,
        });
      }

      if (token === "sOHMSTRONG") {
        approveTx = await sSpaceContract.approve(addresses.STAKING_ADDRESS, ethers.constants.MaxUint256, { gasPrice });
      }

      const text = "Approve " + (token === "OHMSTRONG" ? "Staking" : "Unstaking");
      const pendingTxnType = token === "OHMSTRONG" ? "approve_staking" : "approve_unstaking";

      dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
      await approveTx.wait();
      dispatch(success({ text: messages.tx_successfully_send }));
    } catch (err: any) {
      return metamaskErrorWrap(err, dispatch);
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    await sleep(2);

    const stakeAllowance = await spaceContract.allowance(address, addresses.STAKING_ADDRESS);
    const unstakeAllowance = await sSpaceContract.allowance(address, addresses.STAKING_ADDRESS);

    return dispatch(
      fetchAccountSuccess({
        staking: {
          spaceStake: Number(stakeAllowance),
          sSpaceUnstake: Number(unstakeAllowance),
        },
      }),
    );
  },
);

export const changePresaleApproval = createAsyncThunk(
  "stake/changePresaleApproval",
  async ({ provider, address, networkID }: IChangeApproval, { dispatch }) => {
    if (!provider) {
      dispatch(warning({ text: messages.please_connect_wallet }));
      return;
    }
    const addresses = getAddresses(networkID);

    const signer = provider.getSigner();
    const wethContract = new ethers.Contract(addresses.WETH_ADDRESS, SpaceTokenContract, signer);
    let approveTx;

    try {
      const gasPrice = await getGasPrice(provider);

      approveTx = await wethContract.approve(addresses.PRESALE_ADDRESS, ethers.constants.MaxUint256, {
        gasPrice,
      });
      const text = "Approve weth";
      const pendingTxnType = "approve_presale";

      dispatch(fetchPendingTxns({ txnHash: approveTx.hash, text, type: pendingTxnType }));
      await approveTx.wait();
      dispatch(success({ text: messages.tx_successfully_send }));
    } catch (err: any) {
      return metamaskErrorWrap(err, dispatch);
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    await sleep(2);

    const presaleAllowance = await wethContract.allowance(address, addresses.STAKING_HELPER_ADDRESS);

    return dispatch(
      fetchAccountSuccess({
        balances: {
          presalewethAllowance: Number(presaleAllowance),
        },
      }),
    );
  },
);

export const changePresaleClaim = createAsyncThunk(
  "stake/changePresaleClaim",
  async ({ provider, address, networkID }: IChangeApproval, { dispatch }) => {
    if (!provider) {
      dispatch(warning({ text: messages.please_connect_wallet }));
      return;
    }
    const addresses = getAddresses(networkID);

    const signer = provider.getSigner();
    const presaleContract = new ethers.Contract(addresses.PRESALE_ADDRESS, PresaleContract, signer);
    let claimTx;

    try {
      const gasPrice = await getGasPrice(provider);

      claimTx = await presaleContract.withdraw({
        gasPrice,
      });
      const text = "Claim OHMSTRONG";
      const pendingTxnType = "claim_presale";

      dispatch(fetchPendingTxns({ txnHash: claimTx.hash, text, type: pendingTxnType }));
      await claimTx.wait();
      dispatch(success({ text: messages.tx_successfully_send }));
    } catch (err: any) {
      return metamaskErrorWrap(err, dispatch);
    } finally {
      if (claimTx) {
        dispatch(clearPendingTxn(claimTx.hash));
      }
    }

    await sleep(2);

    return;
  },
);

interface IChangeStake {
  action: string;
  value: string;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
  address: string;
  networkID: Networks;
  warmUpBalance: number;
}

export const changeStake = createAsyncThunk(
  "stake/changeStake",
  async ({ action, value, provider, address, networkID, warmUpBalance }: IChangeStake, { dispatch }) => {
    if (!provider) {
      dispatch(warning({ text: messages.please_connect_wallet }));
      return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const staking = new ethers.Contract(addresses.STAKING_ADDRESS, StakingContract, signer);
    const stakingHelper = new ethers.Contract(addresses.STAKING_HELPER_ADDRESS, StakingHelperContract, signer);
    console.log("stake value", Number(ethers.utils.parseUnits(value, 9)));

    let stakeTx;
    let forfeitTx;

    try {
      const gasPrice = await getGasPrice(provider);

      if (action === "stake" && warmUpBalance > 0) {
        forfeitTx = await staking.forfeit({ gasPrice });
        await forfeitTx.wait();
      }
      if (action === "claim") {
        forfeitTx = await staking.claim(address, { gasPrice });
        await forfeitTx.wait();
      }

      if (action === "stake") {
        stakeTx = await staking.stake(ethers.utils.parseUnits(value, 9), address, { gasPrice });
      } else {
        stakeTx = await staking.unstake(ethers.utils.parseUnits(value, 9), true, { gasPrice });
      }
      const pendingTxnType = action === "stake" ? "staking" : "unstaking";
      dispatch(fetchPendingTxns({ txnHash: stakeTx.hash, text: getStakingTypeText(action), type: pendingTxnType }));
      await stakeTx.wait();
      dispatch(success({ text: messages.tx_successfully_send }));
    } catch (err: any) {
      if (err.data.message.length > 1) {
        return metamaskErrorWrap(err, dispatch);
      }
    } finally {
      if (stakeTx) {
        dispatch(clearPendingTxn(stakeTx.hash));
      }
    }
    dispatch(info({ text: messages.your_balance_update_soon }));
    await sleep(10);
    await dispatch(getBalances({ address, networkID, provider }));
    await dispatch(loadWarmUpInfo({ address, networkID, provider }));
    dispatch(info({ text: messages.your_balance_updated }));
    return;
  },
);

interface IChangePresale {
  action: string;
  value: string;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
  address: string;
  networkID: Networks;
}

export const changePresale = createAsyncThunk(
  "stake/changePresale",
  async ({ action, value, provider, address, networkID }: IChangePresale, { dispatch }) => {
    if (!provider) {
      dispatch(warning({ text: messages.please_connect_wallet }));
      return;
    }
    const addresses = getAddresses(networkID);
    const signer = provider.getSigner();
    const presaleContract = new ethers.Contract(addresses.PRESALE_ADDRESS, PresaleContract, signer);

    let presaleTx;

    try {
      const gasPrice = await getGasPrice(provider);
      console.log(presaleContract);

      presaleTx = await presaleContract.deposit(ethers.utils.parseUnits(value, 6), { gasPrice });
      const pendingTxnType = "presale";
      dispatch(fetchPendingTxns({ txnHash: presaleTx.hash, text: "Depositing weth", type: pendingTxnType }));
      await presaleTx.wait();
      dispatch(success({ text: messages.tx_successfully_send }));
    } catch (err: any) {
      if (err.data.message.length > 1) {
        return metamaskErrorWrap(err, dispatch);
      }
    } finally {
      if (presaleTx) {
        dispatch(clearPendingTxn(presaleTx.hash));
      }
    }
    dispatch(info({ text: messages.your_balance_update_soon }));
    await sleep(10);
    // await dispatch(getBalances({ address, networkID, provider }));
    await dispatch(loadAccountDetails({ address, networkID, provider }));
    await dispatch(loadAppDetails({ networkID, provider }));

    dispatch(info({ text: messages.your_balance_updated }));
    return;
  },
);
