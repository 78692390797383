import { useState, useCallback, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, InputAdornment, OutlinedInput, Zoom } from "@material-ui/core";
import { trim } from "../../helpers";
import "./adminconsole.scss";
import { useWeb3Context } from "../../hooks";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { messages } from "../../constants/messages";
import classnames from "classnames";
import { warning } from "../../store/slices/messages-slice";
import { MouseEvent } from "react";
import { Popper, Fade } from "@material-ui/core";
import { sleep } from "../../helpers";
import WarmUpTimer from "src/components/WarmUpTimer";
import BasicModal from "../../components/Modal";
import { isTeam } from "../../constants";

function AdminConsole() {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();

  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState<string>("");

  const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);

  if (!isTeam(address)) {
    // Not authorized, go away
    return (
      <div className="adminconsole-view">
        <Zoom in={true}>
          <div className="adminconsole-card">
            <Grid className="adminconsole-card-grid" container direction="column" spacing={2}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <div className="adminconsole-card-header">
                    <div className="adminconsole-card-header-title">
                      <p>OHMTRONG Admin Console</p>
                    </div>
                  </div>
                </Grid>
              </Grid>

              <div className="adminconsole-card-area">
                <div className="adminconsole-card-action-area">
                  <div className="adminconsole-card-action-help-text">
                    <p>
                      Sorry, you are not authorized. Check to make sure that you are connected with the right wallet.
                    </p>
                  </div>
                </div>
                <div>
                  <div className="adminconsole-user-data"></div>
                  <div className="adminconsole-card-action-area"></div>
                  <div className="adminconsole-card-wallet-notification"></div>
                </div>
              </div>
            </Grid>
          </div>
        </Zoom>
      </div>
    );
  }

  const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
    return state.pendingTransactions;
  });

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="adminconsole-view">
      <Zoom in={true}>
        <div className="adminconsole-card">
          <Grid className="adminconsole-card-grid" container direction="column" spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="adminconsole-card-header">
                  <div className="adminconsole-card-header-title">
                    <p>OHMSTRONG Admin Console</p>
                  </div>
                </div>
              </Grid>
            </Grid>

            <div className="adminconsole-card-area">
              <div className="adminconsole-card-action-area">
                <div className="adminconsole-card-action-help-text">
                  <p>
                    Here you can see some statistics about the operations of OHMSTRONG. All data is publically
                    accessible from our contracts.
                    <br />
                    <br />
                    Admin functions will come soon, so that we can perform operations like changing APY and bond rates
                    directly from the website.
                  </p>
                </div>
              </div>
              {address ? (
                <div>
                  <div className="adminconsole-user-data">
                    <div className="data-row">
                      <p className="data-row-name">Super Secret Data</p>
                      <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>420.69 AVAX</>}</p>
                    </div>

                    <div className="data-row">
                      <p className="data-row-name">APY</p>
                      <p className="data-row-value">{isAppLoading ? <Skeleton width="80px" /> : <>1 bazillion %</>}</p>
                    </div>
                  </div>
                  <div className="adminconsole-card-action-area">
                    <div className="adminconsole-card-action-row">
                      <div className="adminconsole-card-action-label">DEVS DO SOMETHING</div>
                      <div className="adminconsole-card-tab-panel">
                        <div
                          className="adminconsole-card-tab-panel-btn"
                          onClick={() => {
                            dispatch(warning({ text: "This button doesn't do anything yet!" }));
                          }}
                        >
                          <p>Useless Button</p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="adminconsole-card-wallet-notification"></div>
                </div>
              ) : (
                <div>
                  <div className="adminconsole-user-data"></div>
                  <div className="adminconsole-card-wallet-notification">
                    <div className="adminconsole-card-wallet-connect-btn" onClick={connect}>
                      <p>Connect Wallet</p>
                    </div>
                    <p className="adminconsole-card-wallet-desc-text">
                      Connect your wallet to view and claim AVAX Reflections!
                    </p>
                  </div>
                </div>
              )}
            </div>
          </Grid>
        </div>
      </Zoom>
    </div>
  );
}

export default AdminConsole;
