import AvaxIcon from "../assets/tokens/AVAX.svg";
import MimIcon from "../assets/tokens/MIM.svg";
import SpaceIcon from "../assets/tokens/POHM.png";
import WavaxIcon from "../assets/tokens/WAVAX.png";

export interface IToken {
  name: string;
  address: string;
  img: string;
  isAvax?: boolean;
  decimals: number;
}

// export const avax: IToken = {
//   name: "AVAX",
//   isAvax: true,
//   img: AvaxIcon,
//   address: "",
//   decimals: 18,
// };

// export const mim: IToken = {
//   name: "MIM",
//   address: "0x130966628846BFd36ff31a822705796e8cb8C18D",
//   img: MimIcon,
//   decimals: 18,
// };

const space: IToken = {
  name: "OHMSTRONG",
  address: "0x2Af51C874EFaa7b096005AA03D9a8A87afA7Dd56",
  img: SpaceIcon,
  decimals: 15,
};

export const eth: IToken = {
  name: "WETH",
  address: "0x4200000000000000000000000000000000000006",
  img: WavaxIcon,
  decimals: 18,
};

export default [space];
