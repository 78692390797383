import { Networks } from "../../constants/blockchain";

export enum BondType {
  StableAsset,
  LP,
}

export interface BondAddresses {
  reserveAddress: string;
  bondAddress: string;
}

export interface NetworkAddresses {
  [Networks.MAINNET]: BondAddresses;
  [Networks.TESTNET]: BondAddresses;
  [Networks.HARDHAT]: BondAddresses;
  [Networks.AVAX]: BondAddresses;
}
