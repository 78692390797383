export const getMainnetURI = (): string => {
  // return "http://localhost:8545";
  return "https://mainnet.base.org";
};

export const getTestnetURI = (): string => {
  return "https://goerli.base.org";
};

export const getHardhatURI = (): string => {
  return "http://localhost:8545";
};
