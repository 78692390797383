import { ethers } from "ethers";
import { LpReserveContract } from "../abi";
import { wethOHMSTRONG } from "../helpers/bond";
import { Networks } from "../constants/blockchain";
// import axios from "axios";

// let https = axios.create({
//   // headers: {
//   //   "Accept-Encoding": "*",
//   // },
// });

let marketPrice: number;

export async function getMarketPrice(
  networkID: Networks,
  provider: ethers.Signer | ethers.providers.Provider,
): Promise<number> {
  if (marketPrice) return marketPrice;
  // try {
  //   const response = await https.get(
  //     "https://api.dexscreener.com/latest/dex/tokens/0xacE41ffA123a779428eDE67bf9b82eA2783572b1,0x3355df6D4c9C3035724Fd0e3914dE96A5a83aaf4",
  //   );
  //   for (const pair of response.data.pairs) {
  //     if (pair.baseToken.symbol === "OHMSTRONG" && pair.quoteToken.symbol === "weth") {
  //       marketPrice = Number(pair.priceUsd);
  //       return marketPrice;
  //     }
  //   }
  // } catch (errors) {
  //   console.error(errors);
  // }
  // return 0;
  const wethOHMSTRONGAddress = wethOHMSTRONG.getAddressForReserve(networkID);
  const pairContract = new ethers.Contract(wethOHMSTRONGAddress, LpReserveContract, provider);
  const reserves = await pairContract.getReserves();
  // console.log(Number(reserves[0]), Number(reserves[1]));
  marketPrice = (reserves[0] * 1000) / reserves[1];
  // console.log("marketPrice", marketPrice);
  return marketPrice;
}
