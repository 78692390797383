import { useState, useCallback, SetStateAction } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Grid, InputAdornment, OutlinedInput, Zoom, Divider } from "@material-ui/core";
import RebaseTimer from "../../components/RebaseTimer";
import { trim } from "../../helpers";
import { changeStake, changeApproval } from "../../store/slices/stake-thunk";
import "./stake.scss";
import { useWeb3Context } from "../../hooks";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { Skeleton } from "@material-ui/lab";
import { IReduxState } from "../../store/slices/state.interface";
import { messages } from "../../constants/messages";
import classnames from "classnames";
import { warning } from "../../store/slices/messages-slice";
import { MouseEvent } from "react";
import { Popper, Fade } from "@material-ui/core";
import { forfeitOrClaim } from "../../store/slices/warmup-thunk";
import { sleep } from "../../helpers";
import WarmUpTimer from "src/components/WarmUpTimer";
import BasicModal from "../../components/Modal";
import TriDataRow from "../../components/TriDataRow";

function Stake() {
  const [anchorEl, setAnchorEl] = useState(null);

  const dispatch = useDispatch();
  const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();

  const [view, setView] = useState(0);
  const [quantity, setQuantity] = useState<string>("");

  const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
  const currentIndex = useSelector<IReduxState, string>(state => {
    return state.app.currentIndex;
  });
  const marketPrice = useSelector<IReduxState, number>(state => {
    return state.app.marketPrice;
  });
  const fiveDayRate = useSelector<IReduxState, number>(state => {
    return state.app.fiveDayRate;
  });
  const oneDayRate = useSelector<IReduxState, number>(state => {
    return state.app.oneDayRate;
  });
  const warmupPeriod = useSelector<IReduxState, number>(state => {
    return state.app.warmupPeriod;
  });
  const OHMSTRONGBalance = useSelector<IReduxState, string>(state => {
    return state.account.balances && state.account.balances.OHMSTRONG;
  });
  const warmupBalance = useSelector<IReduxState, string>(state => {
    return state.account.warmupInfo && state.account.warmupInfo.deposit;
  });
  const warmupExpiry = useSelector<IReduxState, string>(state => {
    return state.account.warmupInfo && state.account.warmupInfo.expiry;
  });
  const currentEpoch = useSelector<IReduxState, string>(state => {
    return state.account.warmupInfo && state.account.warmupInfo.epoch;
  });
  const sSpaceBalance = useSelector<IReduxState, string>(state => {
    return state.account.balances && state.account.balances.sOHMSTRONG;
  });
  const sSpacePrice = useSelector<IReduxState, string>(state => {
    return trim(Number(sSpaceBalance) * marketPrice, 2);
  });
  const stakeAllowance = useSelector<IReduxState, number>(state => {
    return state.account.staking && state.account.staking.OHMSTRONG;
  });
  const unstakeAllowance = useSelector<IReduxState, number>(state => {
    return state.account.staking && state.account.staking.sOHMSTRONG;
  });
  const stakingRebase = useSelector<IReduxState, number>(state => {
    return state.app.stakingRebase;
  });
  const stakingAPY = useSelector<IReduxState, number>(state => {
    return state.app.stakingAPY;
  });
  const stakingTVL = useSelector<IReduxState, number>(state => {
    return state.app.stakingTVL;
  });

  const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
    return state.pendingTransactions;
  });

  const setMax = () => {
    if (view === 0) {
      const fullBalance = Number(OHMSTRONGBalance);
      setQuantity(trim(fullBalance, 4));
      // console.log(quantity);
    } else {
      setQuantity(sSpaceBalance);
    }
  };

  const onSeekApproval = async (token: string) => {
    if (await checkWrongNetwork()) return;

    await dispatch(changeApproval({ address, token, provider, networkID: chainID }));
  };

  const onChangeStake = async (action: string) => {
    if (await checkWrongNetwork()) return;
    if (Number(warmupBalance) > 0) {
      const shouldProceed = window.confirm(messages.existing_warmup);
      if (!shouldProceed) {
        return;
      }
    }

    if (quantity === "" || parseFloat(quantity) === 0) {
      dispatch(warning({ text: action === "stake" ? messages.before_stake : messages.before_unstake }));
    } else {
      console.log("quantity", quantity);
      await dispatch(
        changeStake({
          address,
          action,
          value: String(quantity),
          provider,
          networkID: chainID,
          warmUpBalance: Number(warmupBalance),
        }),
      );
      setQuantity("");
    }
  };

  const onChangeWarmup = async (action: string) => {
    if (await checkWrongNetwork()) return;
    if (Number(warmupBalance) === 0) {
      await dispatch(warning({ text: "Nothing to claim in warmup contract" }));
      return;
    }
    await dispatch(
      forfeitOrClaim({
        address,
        action,
        provider,
        networkID: chainID,
      }),
    );
  };

  const hasAllowance = useCallback(
    token => {
      if (token === "OHMSTRONG") return stakeAllowance > Number(quantity);
      if (token === "sOHMSTRONG") return unstakeAllowance > Number(quantity);
      return 0;
    },
    [stakeAllowance],
  );

  const changeView = (newView: number) => () => {
    setView(newView);
    setQuantity("");
  };

  const trimmedMemoBalance = trim(Number(sSpaceBalance), 6);
  const trimmedStakingAPY = trim(stakingAPY * 100, 1);
  const stakingRebasePercentage = trim(stakingRebase * 100, 4);
  const nextRewardValue = trim((Number(stakingRebasePercentage) / 100) * Number(trimmedMemoBalance), 6);

  const rewardPrice = useSelector<IReduxState, string>(state => {
    return trim(Number(nextRewardValue) * marketPrice, 2);
  });

  const dailyRewardPrice = useSelector<IReduxState, string>(state => {
    return trim(Number(rewardPrice), 2);
  });

  const handleClick = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const open = Boolean(anchorEl);

  return (
    <div className="stake-view">
      <Zoom in={true}>
        <div className="stake-card">
          <Grid className="stake-card-grid" container direction="column" spacing={2}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="stake-card-header">
                  <div className="stake-card-header-title">
                    OHMSTRONG Staking (⚡, ⚡)
                    <RebaseTimer />
                  </div>
                  <div className="stake-card-header-value">
                    Daily Earnings - ${trim(Number(sSpacePrice) * oneDayRate, 2)}
                  </div>
                </div>
              </Grid>
            </Grid>
            <Grid item>
              <div className="stake-card-metrics">
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={4} md={4} lg={4}>
                    <div className="stake-card-apy">
                      <p className="stake-card-metrics-title">APY</p>
                      <p className="stake-card-metrics-value">
                        {new Intl.NumberFormat("en-US").format(Number(trimmedStakingAPY))} %
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <div className="stake-card-tvl">
                      <p className="stake-card-metrics-title">TVL</p>
                      <p className="stake-card-metrics-value">
                        {stakingTVL ? (
                          new Intl.NumberFormat("en-US", {
                            style: "currency",
                            currency: "USD",
                            maximumFractionDigits: 0,
                            minimumFractionDigits: 0,
                          }).format(stakingTVL)
                        ) : (
                          <Skeleton width="150px" />
                        )}
                      </p>
                    </div>
                  </Grid>

                  <Grid item xs={6} sm={4} md={4} lg={4}>
                    <div className="stake-card-index">
                      <p className="stake-card-metrics-title">Current Index</p>
                      <p className="stake-card-metrics-value">
                        {currentIndex ? <>{trim(Number(currentIndex), 3)} OHMSTRONG</> : <Skeleton width="150px" />}
                      </p>
                    </div>
                  </Grid>
                </Grid>
              </div>
            </Grid>
            <div className="stake-card-area">
              {!address && (
                <div className="stake-card-wallet-notification">
                  <div className="stake-card-wallet-connect-btn" onClick={connect}>
                    <p>Connect Wallet</p>
                  </div>
                  <p className="stake-card-wallet-desc-text">Connect your wallet to stake OHMSTRONG tokens!</p>
                </div>
              )}
              {address && (
                <>
                  <div className="stake-card-action-area">
                    <div className="stake-card-action-stage-btns-wrap">
                      <div
                        onClick={changeView(0)}
                        className={classnames("stake-card-action-stage-btn", { active: view == 0 })}
                      >
                        <p>Stake</p>
                      </div>
                      <div
                        onClick={changeView(1)}
                        className={classnames("stake-card-action-stage-btn", { active: view == 1 })}
                      >
                        <p>Unstake</p>
                      </div>{" "}
                      <div></div>
                    </div>

                    <div className="stake-card-action-row">
                      <OutlinedInput
                        type="number"
                        placeholder="Amount"
                        className="stake-card-action-input"
                        value={quantity}
                        onChange={e => setQuantity(e.target.value)}
                        labelWidth={0}
                        endAdornment={
                          <InputAdornment position="end">
                            <div onClick={setMax} className="stake-card-action-input-btn">
                              <p>Max</p>
                            </div>
                          </InputAdornment>
                        }
                      />

                      {view === 0 && (
                        <div className="stake-card-tab-panel">
                          {address && hasAllowance("OHMSTRONG") ? (
                            <div
                              className="stake-card-tab-panel-btn"
                              onClick={() => {
                                if (isPendingTxn(pendingTransactions, "staking")) return;
                                onChangeStake("stake");
                              }}
                            >
                              <p>{txnButtonText(pendingTransactions, "staking", "Stake OHMSTRONG")}</p>
                            </div>
                          ) : (
                            <div
                              className="stake-card-tab-panel-btn"
                              onClick={() => {
                                if (isPendingTxn(pendingTransactions, "approve_staking")) return;
                                onSeekApproval("OHMSTRONG");
                              }}
                            >
                              <p>{txnButtonText(pendingTransactions, "approve_staking", "Approve")}</p>
                            </div>
                          )}
                        </div>
                      )}

                      {view === 1 && (
                        <div className="stake-card-tab-panel">
                          {address && hasAllowance("sOHMSTRONG") ? (
                            <div
                              className="stake-card-tab-panel-btn"
                              onClick={() => {
                                if (isPendingTxn(pendingTransactions, "unstaking")) return;
                                onChangeStake("unstake");
                              }}
                            >
                              <p>{txnButtonText(pendingTransactions, "unstaking", "Unstake OHMSTRONG")}</p>
                            </div>
                          ) : (
                            <div
                              className="stake-card-tab-panel-btn"
                              onClick={() => {
                                if (isPendingTxn(pendingTransactions, "approve_unstaking")) return;
                                onSeekApproval("sOHMSTRONG");
                              }}
                            >
                              <p>{txnButtonText(pendingTransactions, "approve_unstaking", "Approve")}</p>
                            </div>
                          )}
                        </div>
                      )}
                    </div>

                    <div className="stake-card-action-help-text">
                      {address && ((!hasAllowance("POHM") && view === 0) || (!hasAllowance("sPOHM") && view === 1)) && (
                        <p>
                          Note: The "Approve" transaction is only needed when staking/unstaking for the first time;
                          subsequent staking/unstaking only requires you to perform the "Stake" or "Unstake"
                          transaction. Because of the recent switch to a new staking contract, you may have to approve
                          again before staking.
                        </p>
                      )}{" "}
                    </div>
                  </div>
                  <Grid>
                    <div className="stake-user-data">
                      <div className="data-row">
                        <p className="data-row-name">Your Balance</p>
                        <p className="data-row-value">
                          {isAppLoading ? <Skeleton width="80px" /> : <>{trim(Number(OHMSTRONGBalance), 4)}OHMSTRONG</>}
                        </p>
                      </div>

                      <Divider />
                      <TriDataRow
                        title="Your Staked Balance"
                        show_right={false}
                        loading={isAppLoading}
                        mid_value={`${trimmedMemoBalance} sOHMSTRONG ($${sSpacePrice})`}
                        right_value={""}
                      />
                      <TriDataRow
                        title="Next Reward Amount"
                        show_right={false}
                        loading={isAppLoading}
                        mid_value={`${nextRewardValue} sOHMSTRONG ($${rewardPrice})`}
                        right_value={""}
                      />
                      <TriDataRow
                        title="Next Reward Yield"
                        show_right={false}
                        loading={isAppLoading}
                        mid_value={`${stakingRebasePercentage}%`}
                        right_value={""}
                      />
                      <TriDataRow
                        title="ROI (5-Day Rate)"
                        show_right={false}
                        loading={isAppLoading}
                        mid_value={`${trim(Number(fiveDayRate) * 100, 4)}%`}
                        right_value={""}
                      />

                      {warmupPeriod > 0 && (
                        <>
                          <Divider />
                          <TriDataRow
                            title="Your Warm Up Balance"
                            show_right={false}
                            loadin={isAppLoading}
                            mid_value={`${trim(Number(warmupBalance), 4)} OHMSTRONG`}
                            right_value={""}
                          />

                          <div className="tri-data-row">
                            <p className="tri-data-row-name">Pending Warm Up Till Release</p>
                            <p className="tri-data-row-mid-nort-value">
                              {isAppLoading ? (
                                <Skeleton width="80px" />
                              ) : Number(warmupExpiry) <= Number(currentEpoch) ? (
                                <>
                                  <div
                                    className="claim-btn"
                                    onClick={() => {
                                      if (isPendingTxn(pendingTransactions, "claim")) return;
                                      onChangeWarmup("claim");
                                    }}
                                  >
                                    <p>{txnButtonText(pendingTransactions, "claim", "Claim sOHMSTRONG")}</p>
                                  </div>
                                  <br />
                                </>
                              ) : (
                                <>
                                  {" "}
                                  {WarmUpTimer()}
                                  <div className="forfeit-btn">{BasicModal(onChangeWarmup)}</div>
                                  {console.log(Number(warmupExpiry))} {console.log(Number(currentEpoch))}
                                </>
                              )}
                            </p>
                          </div>

                          <div className="stake-card-action-help-text">
                            <br />
                            <p>Note: When you stake OHMSTRONG</p>
                          </div>
                        </>
                      )}
                    </div>
                  </Grid>
                </>
              )}
            </div>
          </Grid>
        </div>
      </Zoom>
    </div>
  );
}

export default Stake;
