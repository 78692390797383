import { ethers } from "ethers";
import { getAddresses } from "../../constants";
import { SpaceTokenContract, SSpaceTokenContract, MimTokenContract, StakingContract, PresaleContract } from "../../abi";
import { setAll } from "../../helpers";

import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { Bond } from "../../helpers/bond/bond";
import { Networks } from "../../constants/blockchain";
import React from "react";
import { RootState } from "../store";
import { IToken } from "../../helpers/tokens";
import { NumberLiteralType } from "typescript";
import { messages } from "../../constants/messages";
import { warning } from "../slices/messages-slice";
import { BigNumber } from "ethers";

interface IGetBalances {
  address: string;
  networkID: Networks;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
}

interface IAccountBalances {
  balances: {
    sOHMSTRONG: string;
    OHMSTRONG: string;
  };
}

export const getBalances = createAsyncThunk(
  "account/getBalances",
  async ({ address, networkID, provider }: IGetBalances): Promise<IAccountBalances> => {
    const addresses = getAddresses(networkID);

    const sOHMSTRONGContract = new ethers.Contract(addresses.SOHMSTRONG_ADDRESS, SSpaceTokenContract, provider);
    const sOHMSTRONGBalance = await sOHMSTRONGContract.balanceOf(address);
    const OHMSTRONGContract = new ethers.Contract(addresses.OHMSTRONG_ADDRESS, SpaceTokenContract, provider);
    const OHMSTRONGBalance = await OHMSTRONGContract.balanceOf(address);
    return {
      balances: {
        sOHMSTRONG: ethers.utils.formatUnits(sOHMSTRONGBalance, 9),
        OHMSTRONG: ethers.utils.formatUnits(OHMSTRONGBalance, 9),
      },
    };
  },
);

interface IWarmUpInfo {
  warmupInfo: {
    expiry: number;
    deposit: string;
    epoch: number;
    epochLength: number;
  };
}

interface ILoadAccountDetails {
  address: string;
  networkID: Networks;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
}

export const loadWarmUpInfo = createAsyncThunk(
  "account/loadWarmUpInfo",
  async ({ networkID, provider, address }: ILoadAccountDetails): Promise<IWarmUpInfo> => {
    const addresses = getAddresses(networkID);

    // const stakingContract = new ethers.Contract(addresses.STAKING_ADDRESS, StakingContract, provider);
    // const warmupDetails = await stakingContract.warmupInfo(address);
    // const depositBalance = warmupDetails.deposit;
    // const warmupExpiry = warmupDetails.expiry;
    // const epochDetails = await stakingContract.epoch();
    // const currentEpoch = epochDetails.number;
    // const epochLength = epochDetails.length;
    const warmupExpiry = 0;
    const depositBalance = 0;
    const currentEpoch = 1;
    return {
      warmupInfo: {
        expiry: warmupExpiry,
        deposit: ethers.utils.formatUnits(depositBalance, 9),
        epoch: currentEpoch,
        epochLength: 28800,
        // epochLength: epochLength, // This was returning 4 for some reason.
      },
    };
  },
);

interface IUserAccountDetails {
  balances: {
    OHMSTRONG: string;
    sOHMSTRONG: string;
    weth: string;
    presaleweth: string;
    presalewethAllowance: number;
  };
  staking: {
    OHMSTRONG: number;
    sOHMSTRONG: number;
  };
}

export const loadAccountDetails = createAsyncThunk(
  "account/loadAccountDetails",
  async ({ networkID, provider, address }: ILoadAccountDetails): Promise<IUserAccountDetails> => {
    let OHMSTRONGBalance = 0;
    let sOHMSTRONGBalance = 0;
    let wethBalance = 0;
    let stakeAllowance = 0;
    let unstakeAllowance = 0;
    let wethPresaleAllowance = 0;
    let presaleweth = 0;

    const addresses = getAddresses(networkID);
    // const OHMSTRONGcode = await provider.getCode(addresses.OHMSTRONG_ADDRESS);
    // if (addresses.OHMSTRONG_ADDRESS && OHMSTRONGcode != "0x") {
    const OHMSTRONGContract = new ethers.Contract(addresses.OHMSTRONG_ADDRESS, SpaceTokenContract, provider);
    OHMSTRONGBalance = await OHMSTRONGContract.balanceOf(address);
    stakeAllowance = await OHMSTRONGContract.allowance(address, addresses.STAKING_ADDRESS);
    // }

    // const sOHMSTRONGcode = await provider.getCode(addresses.SOHMSTRONG_ADDRESS);
    // if (addresses.SOHMSTRONG_ADDRESS && sOHMSTRONGcode != "0x") {
    const sOHMSTRONGContract = new ethers.Contract(addresses.SOHMSTRONG_ADDRESS, SSpaceTokenContract, provider);
    sOHMSTRONGBalance = await sOHMSTRONGContract.balanceOf(address);
    // console.log(sOHMSTRONGBalance, address);
    unstakeAllowance = await sOHMSTRONGContract.allowance(address, addresses.STAKING_ADDRESS);
    // }

    // if (addresses.PRESALE_ADDRESS && addresses.weth_ADDRESS) {
    const wethContract = new ethers.Contract(addresses.WETH_ADDRESS, MimTokenContract, provider);
    wethBalance = await wethContract.balanceOf(address);
    wethPresaleAllowance = await wethContract.allowance(address, addresses.PRESALE_ADDRESS);
    const presaleContract = new ethers.Contract(addresses.PRESALE_ADDRESS, PresaleContract, provider);
    presaleweth = await presaleContract.user_deposits(address);
    // }

    return {
      balances: {
        sOHMSTRONG: ethers.utils.formatUnits(sOHMSTRONGBalance, 9),
        OHMSTRONG: ethers.utils.formatUnits(OHMSTRONGBalance, 9),
        weth: ethers.utils.formatUnits(wethBalance, 6),
        presaleweth: ethers.utils.formatUnits(presaleweth, 6),
        presalewethAllowance: Number(ethers.utils.formatUnits(wethPresaleAllowance, 6)),
      },
      staking: {
        OHMSTRONG: Number(stakeAllowance),
        sOHMSTRONG: Number(unstakeAllowance),
      },
    };
  },
);

interface ICalcUserBondDetails {
  address: string;
  bond: Bond;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
  networkID: Networks;
}

export interface IUserBondDetails {
  allowance: number;
  balance: number;
  avaxBalance: number;
  interestDue: number;
  bondMaturationBlock: number;
  pendingPayout: number;
}

export const calculateUserBondDetails = createAsyncThunk(
  "account/calculateUserBondDetails",
  async ({ address, bond, networkID, provider }: ICalcUserBondDetails) => {
    if (!address) {
      return new Promise<any>(resevle => {
        resevle({
          bond: "",
          displayName: "",
          bondIconSvg: "",
          isLP: false,
          allowance: 0,
          balance: 0,
          interestDue: 0,
          bondMaturationBlock: 0,
          pendingPayout: "",
          avaxBalance: 0,
        });
      });
    }

    const bondContract = bond.getContractForBond(networkID, provider);

    const reserveContract = bond.getContractForReserve(networkID, provider);

    let interestDue, pendingPayout, bondMaturationBlock;

    const bondDetails = await bondContract.bondInfo(address);
    interestDue = bondDetails.payout / Math.pow(10, 9);
    bondMaturationBlock = Number(bondDetails.vesting) + Number(bondDetails.lastTime);
    pendingPayout = await bondContract.pendingPayoutFor(address);

    let allowance,
      balance = "0";

    allowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));
    balance = await reserveContract.balanceOf(address);
    const tokenDecimal = await reserveContract.decimals();
    const balanceVal = Number(balance) / Math.pow(10, tokenDecimal);

    const avaxBalance = await provider.getSigner().getBalance();
    const avaxVal = ethers.utils.formatEther(avaxBalance);

    const pendingPayoutVal = ethers.utils.formatUnits(pendingPayout, 9);

    // const pendingPayoutVal = 0;
    return {
      bond: bond.name,
      displayName: bond.displayName,
      bondIconSvg: bond.bondIconSvg,
      isLP: bond.isLP,
      allowance: Number(allowance),
      balance: Number(balanceVal),
      avaxBalance: Number(avaxVal),
      interestDue,
      bondMaturationBlock,
      pendingPayout: Number(pendingPayoutVal),
    };
  },
);

interface ICalcUserTokenDetails {
  address: string;
  token: IToken;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
  networkID: Networks;
}

export interface IUserTokenDetails {
  allowance: number;
  balance: number;
  isAvax?: boolean;
}

export const calculateUserTokenDetails = createAsyncThunk(
  "account/calculateUserTokenDetails",
  async ({ address, token, networkID, provider }: ICalcUserTokenDetails) => {
    if (!address) {
      return new Promise<any>(resevle => {
        resevle({
          token: "",
          address: "",
          img: "",
          allowance: 0,
          balance: 0,
        });
      });
    }

    if (token.isAvax) {
      const avaxBalance = await provider.getSigner().getBalance();
      const avaxVal = ethers.utils.formatEther(avaxBalance);

      return {
        token: token.name,
        tokenIcon: token.img,
        balance: Number(avaxVal),
        isAvax: true,
      };
    }

    const addresses = getAddresses(networkID);

    const tokenContract = new ethers.Contract(token.address, MimTokenContract, provider);
    // console.log(token.address);
    let allowance,
      balance = "0";

    // console.log("HI from", token.address, address);
    allowance = await tokenContract.allowance(address, addresses.ZAPIN_ADDRESS);
    balance = await tokenContract.balanceOf(address);

    const balanceVal = Number(balance) / Math.pow(10, token.decimals);

    return {
      token: token.name,
      address: token.address,
      img: token.img,
      // allowance: Number(allowance),
      allowance: 0,
      balance: Number(balanceVal),
    };
  },
);

export interface IAccountSlice {
  bonds: { [key: string]: IUserBondDetails };
  balances: {
    sOHMSTRONG: string;
    OHMSTRONG: string;
    weth: string;
    presaleweth: string;
    presalewethAllowance: number;
  };
  loading: boolean;
  staking: {
    OHMSTRONG: number;
    sOHMSTRONG: number;
  };
  warmupInfo: {
    expiry: string;
    deposit: string;
    epoch: string;
    epochLength: string;
  };
  tokens: { [key: string]: IUserTokenDetails };
}

const initialState: IAccountSlice = {
  loading: true,
  bonds: {},
  balances: { sOHMSTRONG: "", OHMSTRONG: "", weth: "", presaleweth: "", presalewethAllowance: 0 },
  staking: { OHMSTRONG: 0, sOHMSTRONG: 0 },
  warmupInfo: { expiry: "", deposit: "", epoch: "", epochLength: "28800" },
  tokens: {},
};

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    fetchAccountSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadWarmUpInfo.pending, state => {
        state.loading = true;
      })
      .addCase(loadWarmUpInfo.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadWarmUpInfo.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(loadAccountDetails.pending, state => {
        state.loading = true;
      })
      .addCase(loadAccountDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAccountDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(getBalances.pending, state => {
        state.loading = true;
      })
      .addCase(getBalances.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(getBalances.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(calculateUserBondDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(calculateUserBondDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        const bond = action.payload.bond;
        state.bonds[bond] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserBondDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      })
      .addCase(calculateUserTokenDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(calculateUserTokenDetails.fulfilled, (state, action) => {
        if (!action.payload) return;
        const token = action.payload.token;
        state.tokens[token] = action.payload;
        state.loading = false;
      })
      .addCase(calculateUserTokenDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

export default accountSlice.reducer;

export const { fetchAccountSuccess } = accountSlice.actions;

const baseInfo = (state: RootState) => state.account;

export const getAccountState = createSelector(baseInfo, account => account);
