import React, { useState } from "react";
import "./view-base.scss";
import Header from "../Header";
import { Hidden, makeStyles, useMediaQuery } from "@material-ui/core";
import { DRAWER_WIDTH, TRANSITION_DURATION } from "../../constants/style";
import MobileDrawer from "../Drawer/mobile-drawer";
import Drawer from "../Drawer";
import { cubesImage } from "src/constants/img";
import Messages from "../Messages";

interface IViewBaseProps {
  children: React.ReactNode;
}

const useStyles = makeStyles(theme => ({
  drawer: {
    [theme.breakpoints.up("md")]: {
      width: DRAWER_WIDTH,
      flexShrink: 0,
    },
  },
  content: {
    padding: theme.spacing(1),
    height: "100%",
    overflow: "auto",
    marginLeft: DRAWER_WIDTH,
  },
}));

function ViewBase({ children }: IViewBaseProps) {
  const classes = useStyles();

  const [mobileOpen, setMobileOpen] = useState(false);

  const isSmallerScreen = useMediaQuery("(max-width: 1080px)");
  const isSmallScreen = useMediaQuery("(max-width: 540px)");

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <div className="view-base-root">
      <Messages />
      <Header drawe={!isSmallerScreen} handleDrawerToggle={handleDrawerToggle} />
      <div className={classes.drawer}>
        <Hidden lgUp>
          <MobileDrawer mobileOpen={mobileOpen} handleDrawerToggle={handleDrawerToggle} />
        </Hidden>
        <Hidden mdDown>
          <Drawer />
        </Hidden>
      </div>
      <div className="content">{children}</div>
    </div>
  );
}

export default ViewBase;
