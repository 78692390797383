import React, { useEffect, useState, useCallback } from "react";
import "./presale.scss";
import { useSelector, useDispatch } from "react-redux";
import { useWeb3Context } from "../../hooks";
import { IPendingTxn, isPendingTxn, txnButtonText } from "../../store/slices/pending-txns-slice";
import { Grid, InputAdornment, OutlinedInput, Zoom, LinearProgress, Box } from "@material-ui/core";
import { IReduxState } from "../../store/slices/state.interface";
import { trim } from "../../helpers";
import { Skeleton } from "@material-ui/lab";
import { warning } from "../../store/slices/messages-slice";
import { messages } from "../../constants/messages";
import { changePresale, changePresaleApproval, changePresaleClaim } from "../../store/slices/stake-thunk";

function Presale() {
  const { provider, address, connect, chainID, checkWrongNetwork } = useWeb3Context();
  const dispatch = useDispatch();

  const isAppLoading = useSelector<IReduxState, boolean>(state => state.app.loading);
  const wethBalance = useSelector<IReduxState, string>(state => {
    return state.account.balances && state.account.balances.weth;
  });
  const presaleweth = useSelector<IReduxState, string>(state => {
    return state.account.balances && state.account.balances.presaleweth;
  });
  const presaleMax = useSelector<IReduxState, number>(state => {
    return state.app.presaleMax;
  });
  const presaleCur = useSelector<IReduxState, number>(state => {
    return state.app.presaleCur;
  });
  const presaleStatus = useSelector<IReduxState, string>(state => {
    return state.app.presaleStatus;
  });
  const presaleMaxDep = useSelector<IReduxState, number>(state => {
    return state.app.presaleMaxDep;
  });
  const presaleStart = useSelector<IReduxState, number>(state => {
    return state.app.presaleStart;
  });
  const presaleEnd = useSelector<IReduxState, number>(state => {
    return state.app.presaleEnd;
  });
  const presaleRate = useSelector<IReduxState, number>(state => {
    return state.app.presaleRate;
  });
  const now = useSelector<IReduxState, number>(state => {
    return state.app.now;
  });
  const pendingTransactions = useSelector<IReduxState, IPendingTxn[]>(state => {
    return state.pendingTransactions;
  });
  const presaleAllowance = useSelector<IReduxState, number>(state => {
    return state.account.balances && state.account.balances.presalewethAllowance;
  });

  const trimmedMemoBalance = trim(Number(wethBalance), 2);

  const [quantity, setQuantity] = useState(
    String(Math.min(Number(trimmedMemoBalance), presaleMaxDep - Number(presaleweth))),
  );
  const [OHMSTRONGGet, setOHMSTRONGGet] = useState(Number(presaleweth) / 3);

  const hasAllowance = useCallback(
    token => {
      if (token === "wethPresale") return presaleAllowance > Number(quantity);
      return 0;
    },
    [presaleAllowance],
  );

  const onSeekApproval = async () => {
    if (await checkWrongNetwork()) return;
    await dispatch(changePresaleApproval({ address, provider, networkID: chainID }));
  };

  const onClaimPresale = async () => {
    if (await checkWrongNetwork()) return;
    await dispatch(changePresaleClaim({ address, provider, networkID: chainID }));
  };

  const onChangePresale = async (action: string) => {
    if (await checkWrongNetwork()) return;

    if (quantity === "" || parseFloat(quantity) === 0) {
      dispatch(warning({ text: messages.before_presale }));
    } else if (presaleStatus != "Active") {
      dispatch(warning({ text: messages.presale_inactive }));
    } else {
      await dispatch(
        changePresale({
          address,
          action,
          value: String(quantity),
          provider,
          networkID: chainID,
        }),
      );
      setQuantity("");
    }
  };

  return (
    <div className="presale-view">
      <Zoom in={true}>
        <div className="presale-card">
          <Grid className="presale-card-grid" container direction="column" spacing={2}>
            <Grid item>
              <div className="presale-card-header">
                <p className="presale-card-header-title">DropBot Presale</p>
                <p className="presale-card-header-subtitle">Purchase tokens at launch price</p>
              </div>
            </Grid>
            <Grid item>
              <div className="presale-card-metrics">
                <div className="presale-card-apy">
                  <p className="presale-card-metrics-title">Presale Time</p>
                  <div className="presale-card-metrics-value">
                    {isAppLoading ? (
                      <Skeleton width="100px" />
                    ) : (
                      <>
                        <p>Start: {new Date(presaleStart).toUTCString()}</p>
                        <p>End: {new Date(presaleEnd).toUTCString()}</p>
                      </>
                    )}
                  </div>
                </div>
              </div>
            </Grid>
            <Grid item>
              <div className="presale-card-metrics">
                <div className="presale-card-tvl">
                  <p className="presale-card-metrics-title">Current Time</p>
                  <div className="presale-card-metrics-value">
                    <p>{new Date(now).toUTCString()}</p>
                  </div>
                </div>
                <div className="presale-card-tvl">
                  <p className="presale-card-metrics-title">Presale Status</p>
                  <div className="presale-card-metrics-value">
                    <p>{presaleStatus}</p>
                  </div>
                </div>
              </div>
            </Grid>

            <Grid item>
              <div className="presale-card-metrics">
                <div className="presale-card-tvl">
                  <p className="presale-card-metrics-title">weth Raised</p>
                  <p className="presale-card-metrics-value">
                    {isAppLoading ? (
                      <Skeleton width="100px" />
                    ) : (
                      <>
                        ${new Intl.NumberFormat("en-US").format(presaleCur)} / $
                        {new Intl.NumberFormat("en-US").format(presaleMax)}
                      </>
                    )}
                  </p>
                </div>
              </div>
            </Grid>
            <Grid item>
              <LinearProgress variant="determinate" value={(presaleCur / presaleMax) * 100} />
            </Grid>
            <Grid item>
              <div className="presale-card-metrics">
                <div className="presale-card-index">
                  <p className="presale-card-metrics-title">Your Contribution</p>
                  <p className="presale-card-metrics-value">
                    {isAppLoading ? <Skeleton width="100px" /> : <>{presaleweth} $ETH</>}
                  </p>
                </div>
                <div className="presale-card-index">
                  <p className="presale-card-metrics-title">Your DROPLET Due</p>
                  <p className="presale-card-metrics-value">
                    {isAppLoading ? (
                      <Skeleton width="100px" />
                    ) : (
                      <>
                        {trim(
                          // (Number(Math.min(Number(quantity), presaleMaxDep - Number(presaleweth), 1000000000)) *
                          (Number(presaleweth) * presaleRate) / Math.pow(10, 9),
                          4,
                        )}{" "}
                        DROPLET
                      </>
                    )}
                  </p>
                </div>
              </div>
            </Grid>
            <div className="presale-card-area">
              <div>
                <div className="presale-card-action-area">
                  <div className="presale-card-action-row">
                    <div className="presale-card-tab-panel">
                      <div
                        className="presale-card-tab-panel-btn"
                        onClick={() => {
                          if (isPendingTxn(pendingTransactions, "claim_presale")) return;
                          onClaimPresale();
                        }}
                      >
                        <p>{txnButtonText(pendingTransactions, "claim_presale", "Claim")}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Grid>
        </div>
      </Zoom>
    </div>
  );
}

export default Presale;
