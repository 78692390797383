import { SvgIcon, Link } from "@material-ui/core";
// import { ReactComponent as Twitter } from "../../../assets/icons/twitter.svg";
// import { ReactComponent as Telegram } from "../../../assets/icons/telegram.svg";
import TwitterIcon from "@material-ui/icons/Twitter";
import TelegramIcon from "@material-ui/icons/Telegram";
import LocalLibraryIcon from "@material-ui/icons/LocalLibrary";

export default function Social() {
  return (
    <div className="social-row">
      <Link href="https://twitter.com/dropbot_ETH" target="_blank">
        <SvgIcon color="action" component={TwitterIcon} />
      </Link>

      <Link href="https://t.me/+cV8Goek25hc4Nzkx" target="_blank">
        <SvgIcon color="action" component={TelegramIcon} />
      </Link>

      <Link href="https://drop-bot.gitbook.io/drop-bot/" target="_blank">
        <SvgIcon color="action" component={LocalLibraryIcon} />
      </Link>
    </div>
  );
}
