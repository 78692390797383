import { ethers, constants } from "ethers";
import { getMarketPrice, getTokenPrice } from "../../helpers";
import { calculateUserBondDetails, getBalances } from "./account-slice";
import { getAddresses } from "../../constants";
import { fetchPendingTxns, clearPendingTxn } from "./pending-txns-slice";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider, StaticJsonRpcProvider } from "@ethersproject/providers";
import { fetchAccountSuccess } from "./account-slice";
import { Bond } from "../../helpers/bond/bond";
import { Networks } from "../../constants/blockchain";
import { getBondCalculator } from "../../helpers/bond-calculator";
import { RootState } from "../store";
//import { avaxSpace } from "../../helpers/bond";
import { error, warning, success, info } from "../slices/messages-slice";
import { messages } from "../../constants/messages";
import { getGasPrice } from "../../helpers/get-gas-price";
import { metamaskErrorWrap } from "../../helpers/metamask-error-wrap";
import { sleep } from "../../helpers";
import { abi as TreasuryContract } from "src/abi/TreasuryContract.json";
interface IChangeApproval {
  bond: Bond;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
  networkID: Networks;
  address: string;
}

export const changeApproval = createAsyncThunk(
  "bonding/changeApproval",
  async ({ bond, provider, networkID, address }: IChangeApproval, { dispatch }) => {
    if (!provider) {
      dispatch(warning({ text: messages.please_connect_wallet }));
      return;
    }

    const signer = provider.getSigner();
    const reserveContract = bond.getContractForReserve(networkID, signer);

    let approveTx;
    try {
      const gasPrice = await getGasPrice(provider);
      const bondAddr = bond.getAddressForBond(networkID);
      approveTx = await reserveContract.approve(bondAddr, constants.MaxUint256, { gasPrice });
      dispatch(
        fetchPendingTxns({
          txnHash: approveTx.hash,
          text: "Approving " + bond.displayName,
          type: "approve_" + bond.name,
        }),
      );
      await approveTx.wait();
      dispatch(success({ text: messages.tx_successfully_send }));
    } catch (err: any) {
      metamaskErrorWrap(err, dispatch);
    } finally {
      if (approveTx) {
        dispatch(clearPendingTxn(approveTx.hash));
      }
    }

    await sleep(2);

    let allowance = "0";

    allowance = await reserveContract.allowance(address, bond.getAddressForBond(networkID));

    return dispatch(
      fetchAccountSuccess({
        bonds: {
          [bond.name]: {
            allowance: Number(allowance),
          },
        },
      }),
    );
  },
);

interface ICalcBondDetails {
  bond: Bond;
  value: string | null;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
  networkID: Networks;
}

export interface IBondDetails {
  bond: string;
  bondDiscount: number;
  bondQuote: number;
  purchased: number;
  vestingTerm: number;
  maxBondPrice: number;
  bondPrice: number;
  marketPrice: number;
  maxBondPriceToken: number;
}

export const calcBondDetails = createAsyncThunk(
  "bonding/calcBondDetails",
  async ({ bond, value, provider, networkID }: ICalcBondDetails, { dispatch }) => {
    if (!value) {
      value = "0";
    }

    const amountInWei = ethers.utils.parseUnits(value, bond.decimals);

    let bondPrice = 0,
      bondDiscount = 0,
      valuation = 0,
      bondQuote = 0;

    const addresses = getAddresses(networkID);

    const bondContract = bond.getContractForBond(networkID, provider);
    // const bondCode = await provider.getCode(bondContract.address);
    // if (bondCode === "0x") {
    //   const response = {
    //     bond: bond.name,
    //     bondDiscount,
    //     bondQuote,
    //     purchased: 0,
    //     vestingTerm: 0,
    //     maxBondPrice: 0,
    //     bondPrice,
    //     marketPrice: 0,
    //     maxBondPriceToken: 0,
    //   };
    //   // console.log(response);
    //   return response;
    // }
    const reserveContract = bond.getContractForReserve(networkID, provider);
    const bondCalcContract = getBondCalculator(networkID, provider, bond.name);
    const reserveDecimals = await reserveContract.decimals();

    const terms = await bondContract.terms();
    // console.log("Bond terms", terms);
    const maxBondPrice = (await bondContract.maxPayout()) / Math.pow(10, 9);

    let marketPrice = await getMarketPrice(networkID, provider);

    try {
      bondPrice = (await bondContract.bondPriceInUSD()) / Math.pow(10, 6);
      // console.log("BOND", Number(bondPrice), reserveDecimals, bondPrice / Math.pow(10, reserveDecimals));
      // console.log("Reserve", reserveContract.address);
      //if (bond.name === avaxSpace.name) {
      //  const avaxPrice = getTokenPrice("AVAX");
      //  bondPrice = bondPrice * avaxPrice;
      //}

      // console.log("discount", marketPrice, reserveDecimals, bondPrice);
      bondDiscount = (marketPrice - bondPrice) / bondPrice;
    } catch (e) {
      console.log("error getting bondPriceInUSD", e);
      // bondPrice = 1;
      // bondDiscount = 1;
    }
    let maxBondPriceToken = 0;
    let maxBondValue = ethers.utils.parseUnits("1", reserveDecimals);

    if (bond.isLP) {
      // const amountInWei = ethers.utils.parseUnits(value, 18);
      const treasuryContract = new ethers.Contract(addresses.TREASURY_ADDRESS, TreasuryContract, provider);
      valuation = await treasuryContract.valueOfToken(bond.getAddressForReserve(networkID), amountInWei);
      bondQuote = await bondContract.payoutFor(valuation);
      bondQuote = bondQuote / Math.pow(10, 9);

      const maxValuation = await treasuryContract.valueOfToken(bond.getAddressForReserve(networkID), maxBondValue);
      const maxBondQuote = await bondContract.payoutFor(maxValuation);
      maxBondPriceToken = maxBondPrice / (maxBondQuote * Math.pow(10, -9));
    } else {
      // const amountInWei = ethers.utils.parseUnits(value, 6);
      const reserveDecimals = await reserveContract.decimals();
      bondQuote = await bondContract.payoutFor(amountInWei);
      bondQuote = bondQuote / Math.pow(10, reserveDecimals);
      const maxBondQuote = await bondContract.payoutFor(maxBondValue);
      maxBondPriceToken = maxBondPrice / (maxBondQuote / Math.pow(10, reserveDecimals));
      // console.log(
      //   "MBPT",
      //   Number(bondPrice), // 3990000
      //   Number(valuation), // 0
      //   maxBondPriceToken, // 1995004508.7101896
      //   maxBondPrice, // 500000000
      //   Number(maxBondQuote), // 250626
      //   Number(bondQuote), // 0.250626
      //   Number(maxBondValue), // 1000000
      // );
    }

    if (!!value && bondQuote > maxBondPrice) {
      dispatch(error({ text: messages.try_mint_more(maxBondPrice.toFixed(2).toString()) }));
    }

    // Calculate bonds purchased
    const token = bond.getContractForReserve(networkID, provider);
    let purchased = await token.balanceOf(addresses.TREASURY_ADDRESS);
    if (bond.isLP) {
      const assetAddress = bond.getAddressForReserve(networkID);
      const markdown = await bondCalcContract.markdown(assetAddress);
      purchased = await bondCalcContract.valuation(assetAddress, purchased);
      // console.log(Number(markdown), Number(purchased));
      // purchased = (markdown / Math.pow(10, 6)) * (purchased / Math.pow(10, 15));
      purchased = (purchased * markdown) / Math.pow(10, 15);
      //if (bond.name == avaxSpace.name) {
      //  const avaxPrice = getTokenPrice("AVAX");
      //  purchased = purchased * avaxPrice;
      // }
      /*
    } else if (bond.name === wavax.name) {
    //  purchased = purchased / Math.pow(10, 6);
    //  const avaxPrice = getTokenPrice("AVAX");
    //  purchased = purchased * avaxPrice;
    */
    } else {
      purchased = purchased / Math.pow(10, reserveDecimals);
    }
    // console.log("Stable bond", bondQuote, Number(amountInWei), maxBondPriceToken, Number(purchased));
    // console.log(
    //   "MBPT",
    //   Number(bondPrice), // 3800000000000000000
    //   Number(valuation), // 63245553203367
    //   maxBondPriceToken, // 15020.818886219606
    //   maxBondPrice, // 500000000
    //   Number(maxBondQuote), // 33287133264
    //   Number(maxValuation), // 63245553203367620
    //   Number(valuation), // 63245553203367
    //   Number(bondQuote), // 33.287133
    //   Number(maxBondValue), // 1000000000000000000
    // );

    const response = {
      bond: bond.name,
      bondDiscount,
      bondQuote,
      purchased,
      vestingTerm: Number(terms.vestingTerm),
      maxBondPrice,
      bondPrice,
      marketPrice,
      maxBondPriceToken,
    };
    // console.log(response);
    return response;
  },
);

interface IBondAsset {
  value: string;
  address: string;
  bond: Bond;
  networkID: Networks;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
  slippage: number;
  useAvax: boolean;
}
export const bondAsset = createAsyncThunk(
  "bonding/bondAsset",
  async ({ value, address, bond, networkID, provider, slippage, useAvax }: IBondAsset, { dispatch }) => {
    const depositorAddress = address;
    const acceptedSlippage = slippage / 100 || 0.005;
    const valueInWei = ethers.utils.parseUnits(value, bond.decimals);
    // const valueInWei = value;
    const signer = provider.getSigner();
    const bondContract = bond.getContractForBond(networkID, signer);

    const calculatePremium = await bondContract.bondPrice();
    // console.log("calculatePremium", calculatePremium.toString(), Number(valueInWei));
    const maxPremium = Math.round(calculatePremium * (1 + acceptedSlippage));

    // const addresses = getAddresses(networkID);
    // console.log("HI");
    // const treasuryContract = new ethers.Contract(addresses.TREASURY_ADDRESS, TreasuryContract, provider);
    // console.log(
    //   Number(valueInWei),
    //   Number(await treasuryContract.valueOfToken(bond.getAddressForReserve(networkID), valueInWei)),
    // );
    // console.log(Number(await bondContract.bondPrice()), Number(await bondContract.bondPriceInUSD()));
    let bondTx;
    try {
      const gasPrice = await getGasPrice(provider);

      if (useAvax) {
        bondTx = await bondContract.deposit(valueInWei, maxPremium, depositorAddress, { value: valueInWei, gasPrice });
      } else {
        console.log("bonding", Number(valueInWei), maxPremium, depositorAddress);
        // console.log(bondContract.address);
        bondTx = await bondContract.deposit(valueInWei, maxPremium, depositorAddress, { gasPrice });
      }
      dispatch(
        fetchPendingTxns({
          txnHash: bondTx.hash,
          text: "Bonding " + bond.displayName,
          type: "bond_" + bond.name,
        }),
      );
      await bondTx.wait();
      dispatch(success({ text: messages.tx_successfully_send }));
      dispatch(info({ text: messages.your_balance_update_soon }));
      await sleep(10);
      await dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
      dispatch(info({ text: messages.your_balance_updated }));
      return;
    } catch (err: any) {
      return metamaskErrorWrap(err, dispatch);
    } finally {
      if (bondTx) {
        dispatch(clearPendingTxn(bondTx.hash));
      }
    }
  },
);

interface IRedeemBond {
  address: string;
  bond: Bond;
  networkID: Networks;
  provider: StaticJsonRpcProvider | JsonRpcProvider;
  autostake: boolean;
}

export const redeemBond = createAsyncThunk(
  "bonding/redeemBond",
  async ({ address, bond, networkID, provider, autostake }: IRedeemBond, { dispatch }) => {
    if (!provider) {
      dispatch(warning({ text: messages.please_connect_wallet }));
      return;
    }

    const signer = provider.getSigner();
    const bondContract = bond.getContractForBond(networkID, signer);

    let redeemTx;
    try {
      const gasPrice = await getGasPrice(provider);
      // console.log("redeem", address, autostake === true);
      redeemTx = await bondContract.redeem(address, autostake === true, { gasPrice });
      const pendingTxnType = "redeem_bond_" + bond.name + (autostake === true ? "_autostake" : "");
      dispatch(
        fetchPendingTxns({
          txnHash: redeemTx.hash,
          text: "Redeeming " + bond.displayName,
          type: pendingTxnType,
        }),
      );
      await redeemTx.wait();
      dispatch(success({ text: messages.tx_successfully_send }));
      await sleep(0.01);
      dispatch(info({ text: messages.your_balance_update_soon }));
      await sleep(10);
      await dispatch(calculateUserBondDetails({ address, bond, networkID, provider }));
      await dispatch(getBalances({ address, networkID, provider }));
      dispatch(info({ text: messages.your_balance_updated }));
      return;
    } catch (err: any) {
      metamaskErrorWrap(err, dispatch);
    } finally {
      if (redeemTx) {
        dispatch(clearPendingTxn(redeemTx.hash));
      }
    }
  },
);

export interface IBondSlice {
  loading: boolean;
  [key: string]: any;
}

const initialState: IBondSlice = {
  loading: true,
};

const setBondState = (state: IBondSlice, payload: any) => {
  const bond = payload.bond;
  const newState = { ...state[bond], ...payload };
  state[bond] = newState;
  state.loading = false;
};

const bondingSlice = createSlice({
  name: "bonding",
  initialState,
  reducers: {
    fetchBondSuccess(state, action) {
      state[action.payload.bond] = action.payload;
    },
  },
  extraReducers: builder => {
    builder
      .addCase(calcBondDetails.pending, state => {
        state.loading = true;
      })
      .addCase(calcBondDetails.fulfilled, (state, action) => {
        setBondState(state, action.payload);
        state.loading = false;
        // })
        // .addCase(calcBondDetails.rejected, (state, { error }) => {
        //   state.loading = false;
        //   console.log(error);
      });
  },
});

export default bondingSlice.reducer;

export const { fetchBondSuccess } = bondingSlice.actions;

const baseInfo = (state: RootState) => state.bonding;

export const getBondingState = createSelector(baseInfo, bonding => bonding);
