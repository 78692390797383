import React, { useEffect, useState } from "react";
import App from "./App";
//import Dashboard from "./Dashboard";
import { HashRouter } from "react-router-dom";
import { loadTokenPrices } from "../helpers";
import Loading from "../components/Loader";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { purple } from "@material-ui/core/colors";

const theme = createTheme({
  palette: {
    primary: purple,
    type: "dark",
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1400,
      xl: 1536,
    },
  },
});

function Root() {
  const isApp = (): boolean => {
    return window.location.host.includes("app");
  };

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    loadTokenPrices().then(() => setLoading(false));
  }, []);

  if (loading) return <Loading />;

  const app = () => (
    <HashRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </HashRouter>
  );

  return isApp() ? (
    app()
  ) : (
    <HashRouter>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </HashRouter>
  );
}

export default Root;
