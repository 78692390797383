import { StaticJsonRpcProvider } from "@ethersproject/providers";
import { Networks } from "../constants/blockchain";
import { BondingCalcContract } from "../abi";
import { ethers } from "ethers";
import { getAddresses } from "../constants/addresses";

export function getBondCalculator(networkID: Networks, provider: StaticJsonRpcProvider, name: string) {
  const addresses = getAddresses(networkID);
  //if (name !== "avax_qi_lp") {
  return new ethers.Contract(addresses.OHMSTRONG_BONDING_CALC_ADDRESS, BondingCalcContract, provider);
  //} else {
  //  return new ethers.Contract(addresses.QI_BONDING_CALC_ADDRESS, BondingCalcContract, provider);
  //}
}
