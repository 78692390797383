import { ethers } from "ethers";
import { getAddresses } from "../../constants";
import {
  StakingContract,
  SSpaceTokenContract,
  SpaceTokenContract,
  DistributorContract,
  TreasuryContract,
  PresaleContract,
} from "../../abi";
import { setAll } from "../../helpers";
import { createSlice, createSelector, createAsyncThunk } from "@reduxjs/toolkit";
import { JsonRpcProvider } from "@ethersproject/providers";
import { getMarketPrice, getTokenPrice } from "../../helpers";
import { RootState } from "../store";
import allBonds from "../../helpers/bond";
import fetch from "node-fetch";

interface ILoadAppDetails {
  networkID: number;
  provider: JsonRpcProvider;
}

export const loadAppDetails = createAsyncThunk(
  "app/loadAppDetails",
  //@ts-ignore
  async ({ networkID, provider }: ILoadAppDetails) => {
    const addresses: any = getAddresses(networkID);
    const currentBlock = await provider.getBlockNumber();
    const currentBlockTime = (await provider.getBlock(currentBlock)).timestamp;

    // const sOHMSTRONGCode = await provider.getCode(addresses.SOHMSTRONG_ADDRESS);
    // let circ;
    // if (sOHMSTRONGCode === "0x") {
    //   circ = 0;
    // } else {
    const sOHMSTRONGContract = new ethers.Contract(addresses.SOHMSTRONG_ADDRESS, SSpaceTokenContract, provider);
    const circ = await sOHMSTRONGContract.circulatingSupply();
    // }
    const OHMSTRONGContract = new ethers.Contract(addresses.OHMSTRONG_ADDRESS, SpaceTokenContract, provider);
    const presaleContract = new ethers.Contract(addresses.PRESALE_ADDRESS, PresaleContract, provider);

    const presaleMax = (await presaleContract.maxTotalDeposit()) / Math.pow(10, 6);
    const presaleCur = (await presaleContract.totalDeposited()) / Math.pow(10, 6);
    const presaleMaxDep = (await presaleContract.maxDepositPerPerson()) / Math.pow(10, 6);
    const presaleStart = Number(await presaleContract.startTime()) * 1000;
    const presaleEnd = Number(await presaleContract.endTime()) * 1000;
    const presaleFinalized = await presaleContract.saleFinalized();
    const presaleRate = await presaleContract.exchangeRate();
    const now = Number(currentBlockTime) * 1000;
    const marketPrice = await getMarketPrice(networkID, provider);
    const burnedSupply = (await OHMSTRONGContract.balanceOf(addresses.BURN_ADDRESS)) / Math.pow(10, 9);
    const totalSupply = (await OHMSTRONGContract.totalSupply()) / Math.pow(10, 9) - burnedSupply;
    const circSupply = circ / Math.pow(10, 9);
    const stakingTVL = circSupply * marketPrice;
    const marketCap = totalSupply * marketPrice;
    const tokenBalPromises = allBonds.map(bond => bond.getTreasuryBalance(networkID, provider));
    const tokenBalances = await Promise.all(tokenBalPromises);
    const treasuryBalance = tokenBalances.reduce((tokenBalance0, tokenBalance1) => tokenBalance0 + tokenBalance1);
    const tokenAmountsPromises = allBonds.map(bond => bond.getTokenAmount(networkID, provider));
    const tokenAmounts = await Promise.all(tokenAmountsPromises);
    const rfvTreasury = tokenAmounts.reduce((tokenAmount0, tokenAmount1) => tokenAmount0 + tokenAmount1, 0);

    const spaceBondsAmountsPromises = allBonds.map(bond => bond.getSpaceAmount(networkID, provider));
    const spaceBondsAmounts = await Promise.all(spaceBondsAmountsPromises);
    const spaceAmount = spaceBondsAmounts.reduce((spaceAmount0, spaceAmount1) => spaceAmount0 + spaceAmount1, 0);
    const spaceSupply = totalSupply - spaceAmount;

    const rfv = rfvTreasury / spaceSupply;

    // const stakingCode = await provider.getCode(addresses.STAKING_ADDRESS);
    let epoch, nextRebase, warmupPeriod, stakingReward, currentIndex;
    // if (stakingCode === "0x") {
    //   epoch = 0;
    //   nextRebase = 0;
    //   warmupPeriod = 0;
    //   stakingReward = 0;
    //   currentIndex = 1;
    // } else {
    const stakingContract = new ethers.Contract(addresses.STAKING_ADDRESS, StakingContract, provider);
    epoch = await stakingContract.epoch();
    nextRebase = epoch.endTime;
    warmupPeriod = await stakingContract.warmupPeriod();
    const distributorContract = new ethers.Contract(addresses.DISTRIBUTOR_ADDRESS, DistributorContract, provider);
    stakingReward = await distributorContract.nextRewardFor(stakingContract.address);
    currentIndex = await stakingContract.index();
    // }
    const stakingRebase = stakingReward / circ;
    const oneDayRate = Math.pow(1 + stakingRebase, 3) - 1;
    const fiveDayRate = Math.pow(1 + stakingRebase, 15) - 1;
    const stakingAPY = Math.pow(1 + stakingRebase, 3 * 365) - 1;

    const treasuryRunway = rfvTreasury / circSupply;
    const runway = Math.log(treasuryRunway) / Math.log(1 + stakingRebase) / 3;

    let presaleStatus;
    if (presaleStart > now) {
      presaleStatus = "Not started";
    }
    if (presaleStart <= now && presaleEnd >= now) {
      presaleStatus = "Active";
    }
    if (presaleEnd < now || presaleCur == presaleMax) {
      presaleStatus = "Ended";
    }
    if (presaleFinalized) presaleStatus = "Finalized";

    return {
      currentIndex: Number(ethers.utils.formatUnits(currentIndex, "gwei")) / Math.pow(10, 2),
      totalSupply,
      marketCap,
      currentBlock,
      circSupply,
      oneDayRate,
      fiveDayRate,
      treasuryBalance,
      stakingAPY,
      stakingTVL,
      stakingRebase,
      marketPrice,
      currentBlockTime,
      nextRebase,
      rfv,
      runway,
      presaleMax,
      presaleCur,
      presaleMaxDep,
      presaleStart,
      presaleEnd,
      presaleStatus,
      presaleRate,
      warmupPeriod,
      now,
    };
  },
);

const initialState = {
  loading: true,
};

export interface IAppSlice {
  loading: boolean;
  stakingTVL: number;
  marketPrice: number;
  marketCap: number;
  circSupply: number;
  currentIndex: string;
  currentBlock: number;
  currentBlockTime: number;
  oneDayRate: number;
  fiveDayRate: number;
  treasuryBalance: number;
  stakingAPY: number;
  stakingRebase: number;
  networkID: number;
  nextRebase: number;
  totalSupply: number;
  rfv: number;
  runway: number;
  presaleMax: number;
  presaleCur: number;
  presaleMaxDep: number;
  presaleStart: number;
  presaleEnd: number;
  presaleStatus: string;
  presaleRate: number;
  warmupPeriod: number;
  now: number;
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    fetchAppSuccess(state, action) {
      setAll(state, action.payload);
    },
  },
  extraReducers: builder => {
    builder
      .addCase(loadAppDetails.pending, (state, action) => {
        state.loading = true;
      })
      .addCase(loadAppDetails.fulfilled, (state, action) => {
        setAll(state, action.payload);
        state.loading = false;
      })
      .addCase(loadAppDetails.rejected, (state, { error }) => {
        state.loading = false;
        console.log(error);
      });
  },
});

const baseInfo = (state: RootState) => state.app;

export default appSlice.reducer;

export const { fetchAppSuccess } = appSlice.actions;

export const getAppState = createSelector(baseInfo, app => app);
